<template>
  <div class="AngeboteSlider part">
    <div class="constrain">
      <line-box class="col-12 mb-12">
        <span v-html="payload.text" />
      </line-box>
      <div class="slider-box">
        <slider
          ref="sliderRef"
          v-slot="{ item: angebot }"
          class="slider"
          :slides-per-view="{ desktop: 4 }"
          :slides-per-group="{ desktop: 1 }"
          :items="payload.offers"
          @progress="onProgress"
        >
          <Go :to="angebot.link" class="angebot">
            <Image :src="angebot.vorschaubild" />
            <line-box class="box" color="white">
              {{ angebot.titel || angebot.title }}
            </line-box>
          </Go>
        </slider>
        <div class="arrow left" v-if="hasPrev"  @click="prev" >
          <arrow-left />
        </div>
        <div class="arrow right" v-if="hasNext" @click="next" >
          <arrow-right />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import SwiperCore, { Navigation } from 'swiper';
import ArrowLeft from '@/assets/images/Pfeil_Gallery_left.svg';
import ArrowRight from '@/assets/images/Pfeil_Gallery_right.svg';

SwiperCore.use([Navigation]);

export default {
  components: {
    ArrowLeft,
    ArrowRight,
  },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const sliderRef = ref(null);
    const progress = ref(0);

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    const onProgress = (_, nextProgress) => { progress.value = nextProgress; };

    const hasNext = computed(() => progress.value < 1);
    const hasPrev = computed(() => progress.value > 0);

    return {
      prev,
      next,
      hasNext,
      hasPrev,
      onProgress,
      sliderRef,
      boxed: computed(() => props.payload.optionen?.includes('block')),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_type.scss";

:deep(a) {
  @extend .li2;
}

.angebot {
  display: block;
  width: 100%;
  aspect-ratio: 5 / 3;
  overflow: hidden;
  position: relative;
  color: white;

  :deep(img) {
    @include full-overlay();
    object-fit: cover;
    transition: transform var(--speed-fast) ease;
  }

  &:hover {
    color: white;

    :deep(img) {
      transform: scale(1.2);
    }
  }
}

.box {
  @extend .n2b;
  position: absolute;
  left: px(10);
  bottom: 10%;
  white-space: nowrap;
}

.link {
  @extend .li1;
}

.slider-box {
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    height: 40px;
    justify-content: center;
    align-items: center;
    width: 40px;
    cursor: pointer;
    display: flex;
    transform: translateY(-50%);
    border-radius: 50%;
    background: white;
    box-shadow: rgb(0 0 0 / 36%) 0px 1px 3px 0px;

    &.right {
      right: 0;
    }
    &.left {
      left: 0;
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }
}
</style>
